import React from "react";


const Donation = () => {
    const handleSupportClick = () => {
        window.open('https://www.linkedin.com/in/chaitanya-bhise-98a41520a/', '_blank', 'noopener,noreferrer');
    };

    return (
      <div>
        <button onClick={handleSupportClick} style={{ backgroundColor: 'darkslategrey', color: '#90EE90', padding: '10px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
        Linkedin
        </button>
      </div>
    );
};

export default Donation;
